<script>
import moment from 'moment'

export default {
  name: 'Detail',
  props: {
    event: { type: Object },
    recurrence: { type: Object }
  },
  data() {
    return {
      currentEvent: {},
      currentRecurrence: {}
    }
  },
  created() {
    this.currentEvent = this.event
    this.currentRecurrence = this.recurrence
    console.log(this.currentEvent)
  },
  methods: {
    formatDateTime(date) {
      return date ? moment(date).format("DD/MM/yyyy HH:mm") : '-'
    }
  }
}
</script>

<template>
<div class="eventDetail">
  <p v-if="currentEvent.extendedProps && currentEvent.extendedProps.professional">Nome do Profissional: {{ currentEvent.extendedProps.professional.name }}</p>
  <p v-if="currentEvent.extendedProps && currentEvent.extendedProps.treatment">Tratamento: <b-badge variant="info" class="ml-1">{{ currentEvent.extendedProps.treatment.name }}</b-badge><b-badge variant="primary" class="ml-1">{{ currentEvent.extendedProps.approach.name }}</b-badge></p>
  <p v-if="currentEvent.extendedProps && currentEvent.extendedProps.agreement">Convênio: {{ currentEvent.extendedProps.agreement.name }}</p>
  <p>Horário início do atendimento: {{ formatDateTime(currentEvent.start) }}</p>
  <p>Horário fim do atendimento: {{ formatDateTime(currentEvent.end) }}</p>
</div>
</template>